/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 68번째 로그에서는 펭귄 님을 모시고 루비 2.7 릴리스, IRB 개선, 루비의 미래, RubyMine 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "단신"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/67/"
  }, "스탠다드아웃_067.log: RDS 인증서 업데이트, 파이썬 2.7 지원 종료, 스톡옵션 등 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--amazon-eks-price-reduction"
  }, "아마존 EKS(Amazon EKS) 50% 요금 인하 발표 - 시간당 0.10 USD | 44BITS 기술 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gsuiteupdates.googleblog.com/2020/01/windows-10-gsuite-admin-single-sign-on-sso.html"
  }, "G Suite Updates Blog: Manage Windows 10 devices through the G Suite Admin console")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Facebook-Inside-Story-Steven-Levy/dp/0735213151/ref=sr_1_1?keywords=facebook+steven+levy&qid=1580347148&sr=8-1"
  }, "Facebook: The Inside Story: Steven Levy: 9780735213159: Amazon.com: Books")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mobile.twitter.com/insightbook/status/1218030801926754304"
  }, "INSIGHTBOOK on Twitter: \"Hackers의 지은이 스티븐 레비(Steven Levy)가 페이스북을 다룬 책 집필...")), "\n"), "\n", React.createElement(_components.h2, null, "루비 카이기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2020"
  }, "RubyKaigi 2020")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.doorkeeper.jp/events/102599"
  }, "RubyKaigi 2020 - RubyKaigi | Doorkeeper")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyconf.org/"
  }, "RubyConf 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "AWS re:Invent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%A7%88%EC%93%B0%EB%AA%A8%ED%86%A0%EC%8B%9C"
  }, "마쓰모토시 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://visitmatsumoto.com/ko/coverstory/climbingalps/"
  }, "북알프스 등산 | 마츠모토에 가보자")), "\n"), "\n", React.createElement(_components.h2, null, "루비 2.7 릴리스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/1/"
  }, "stdout_001.log: 루비 25주년, Github 장애 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/ko/news/2019/12/25/ruby-2-7-0-released/"
  }, "루비 2.7.0 릴리스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://employment.en-japan.com/engineerhub/entry/2019/12/26/103000"
  }, "(일본어) 루비 2.7 여기에 주목! 패턴 매치, 컴팩션GC 등 릴리스 매니저에게 들어보았습니다 - 엔지니어허브")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcommunity.microsoft.com/t5/excel-blog/announcing-office-scripts-preview/ba-p/1093559"
  }, "Announcing Office Scripts (Preview) - Microsoft Tech Community - 1093559")), "\n"), "\n", React.createElement(_components.h2, null, "IRB 개선"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tiswww.case.edu/php/chet/readline/rltop.html"
  }, "The GNU Readline Library")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.iamseapy.com/archives/177"
  }, "루비(Ruby) 콘솔(IRB, PRY)에서 한글 입력하기 | 오늘부터 seapy는")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Read%E2%80%93eval%E2%80%93print_loop"
  }, "Read–eval–print loop - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pry/pry"
  }, "pry/pry: A runtime developer console and IRB alternative with powerful introspection capabilities.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ipython.org/"
  }, "Jupyter and the future of IPython — IPython")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ruby/reline"
  }, "ruby/reline: The compatible library with the API of Ruby's stdlib 'readline'")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.saeloun.com/2019/09/23/new-irb-features.html"
  }, "What's new in Interactive Ruby Shell (IRB) with Ruby 2.7 – Saeloun Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ruby/irb/issues/43"
  }, "pasting in multiline irb is slow · Issue #43 · ruby/irb")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyprize.jp/ja/nominees_19.html"
  }, "(일본어) RubyPrize 2019｜최종 선정작 발표")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.datadoghq.com/monitors/faq/how-to-update-anomaly-monitor-timezone/"
  }, "How to update an anomaly detection monitor to account for local time zone")), "\n"), "\n", React.createElement(_components.h2, null, "그 외 루비 2.7 관련 변경 사항 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--ruby-2-7-released"
  }, "프로그래밍 언어 루비(Ruby) 2.7 릴리스 및 주요 변경 사항: 패턴 매칭 문법, REPL 개선, 위치 인자와 키워드 인자 분리, 번호 파라미터 등 | 44BITS 기술 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/en/news/2019/12/12/separation-of-positional-and-keyword-arguments-in-ruby-3-0/"
  }, "Separation of positional and keyword arguments in Ruby 3.0")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/16123"
  }, "Feature #16123: Allow calling a private method with ", React.createElement(_components.code, null, "self."), " - Ruby master - Ruby Issue Tracking System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/4475"
  }, "Feature #4475: default variable name for parameter - Ruby master - Ruby Issue Tracking System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/14240"
  }, "Feature #14240: warn four special variables: $; $, $/ $\\ - Ruby master - Ruby Issue Tracking System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/14912"
  }, "Feature #14912: Introduce pattern matching syntax - Ruby master - Ruby Issue Tracking System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.swift.org/swift-book/ReferenceManual/Patterns.html"
  }, "Patterns — The Swift Programming Language (Swift 5.1)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/how-to-create-aws-lambda-custom-runtime-feat-ruby-2-6-0"
  }, "AWS 람다(AWS Lambda) 커스텀 런타임 만들기(feat. 루비 2.6.0) | 44BITS 기술 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/14799"
  }, "Feature #14799: Startless range - Ruby master - Ruby Issue Tracking System")), "\n"), "\n", React.createElement(_components.h2, null, "Ruby의 미래: 2020년 3.0 릴리스 예정"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=2g9R7PUCEXo"
  }, "RubyConf 2019 - Opening Keynote - Ruby Progress Report by Yukihiro Matzumoto (Matz) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/LaTeX"
  }, "LaTeX - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ja.reactjs.org/blog/2016/04/07/react-v15.html"
  }, "React v15.0 – React Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019/presentations/mametter.html#apr18"
  }, "A Type-level Ruby Interpreter for Testing and Understanding - RubyKaigi 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sorbet.org/"
  }, "Sorbet · A static type checker for Ruby")), "\n"), "\n", React.createElement(_components.h2, null, "RubyMine"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://apidock.com/rails/ActiveRecord/Transactions/ClassMethods/after_commit"
  }, "after_commit (ActiveRecord::Transactions::ClassMethods) - APIdock")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/ko-kr/ruby/"
  }, "RubyMine: JetBrains가 만든 Ruby on Rails IDE")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/ko-kr/"
  }, "JetBrains: 전문가와 팀을 위한 개발자 툴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/chetanp/status/1205907182396395525"
  }, "Chetan Puttagunta on Twitter: \"JetBrains, a Prague based developer tools company....")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/JetBrains"
  }, "JetBrains - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://macromates.com/"
  }, "TextMate: Text editor for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://railscasts.com/"
  }, "Ruby on Rails Screencasts - RailsCasts")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/student/"
  }, "For Students: Free Professional Developer Tools by JetBrains")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
